import { Injectable } from '@angular/core';
import { ApiHttpService, ApiListResult, ApiService, ListOptions } from '@capturum/api';
import { Organisation } from '@core/models/organisation.model';
import { BaseKey } from '@core/enums/base-key.enum';
import { OrganisationType } from '@core/enums/organisation-type.enum';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { MapItem } from '@capturum/ui/api';
import { ConverterUtil } from '@core/utils/converter-util';
import { DxpIndexableBaseDataService } from '@core/indexDb/services/dxp-indexable-base-data.service';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService extends ApiService<Organisation> {
  protected endpoint = 'organisation';

  constructor(
    public apiHttp: ApiHttpService,
    private dxpIndexableBaseDataService: DxpIndexableBaseDataService,
  ) {
    super(apiHttp);
  }

  public restore(id: string, options?: ListOptions): Observable<Organisation> {
    let url = `/${this.endpoint}/${id}/restore`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.patch(url, {});
  }

  public getItemsAsListOptions(options?: ListOptions): Observable<MapItem[]> {
    return this.list(options).pipe(
      map((response) => {
        return ConverterUtil.listToOption(response?.data);
      }),
    );
  }

  public getOrganisationListByType(organisationType: OrganisationType): Observable<any> {
    return this.dxpIndexableBaseDataService.getBaseDataValuesByDataKey(BaseKey.organisationType).pipe(
      mergeMap((organisationTypeBaseDataValues) => {
        if (!organisationTypeBaseDataValues) {
          return of<ApiListResult>(null);
        }

        const organisationTypeBaseDataValue = organisationTypeBaseDataValues.find((org) => {
          return org?.key === organisationType;
        });

        let options: ListOptions = {};

        if (organisationTypeBaseDataValue) {
          options = {
            filters: [
              {
                field: 'type_base_data_value_id',
                value: organisationTypeBaseDataValue.value,
              },
            ],
          };
        }

        return this.list(options).pipe(
          map((result) => {
            return result?.data.map((item) => {
              return {
                key: item.key,
                value: item.key,
                label: item.label,
              };
            });
          }),
        );
      }),
    );
  }
}
